import {
  getCoreRowModel,
  useReactTable,
  flexRender,
  getFilteredRowModel,
} from "@tanstack/react-table";
import type { ColumnDef } from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import "./leaderboard.css";
import { FaRedhat, FaTrophy } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { useGameStore } from "../../../store/store";
import { Button, Checkbox, Skeleton } from "@chakra-ui/react";
import Select from "react-select";
import { checkSubResourcePermission } from "helper/checkResorcePermission";
import axiosClient from "config/apiClient";
import { useCommonToast } from "components/toast/toast";

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* @ts-ignore */
interface LeaderboardData {
  data_dict: {
    total: string;
    team_name: string;
    team_id: number;
    RevenuePeriod1: string;
    RevenuePeriod2: string;
    RevenuePeriod3: string;
    PricePeriod1: string;
    PricePeriod2: string;
    PricePeriod3: string;
    inventory_Period1: string;
    inventory_Period2: string;
    inventory_Period3: string;
    inventory_balance: string;
  }[];
  teamname: string;
  num_teams: number;
}

interface Option {
  label: string;
  value: string;
}

interface SelectOptions {
  tenant_id: Option;
  workshop_id: Option;
}

interface TableProps {
  data: LeaderboardData["data_dict"];
  tenantOption?: Option[];
  workshopOptions?: Option[];
  columns: ColumnDef<LeaderboardData["data_dict"][0]>[];
  teamname: string;
  numberofTeams: number;
  BlurActive: boolean;
  selectOptions: SelectOptions;
  isLoading?: boolean;
  isSecondaryLoading?: boolean;
  isFetching?: boolean;
  handleResetAll: () => Promise<void>;
  getReset: (rowData: any) => Promise<void>;
  handleGameChange: (selectedOption: any, fieldId: string) => void;
  getAdminLeaderBoard: (tenant_id: Option, workshop_id: Option) => void;
  setselectOptions: React.Dispatch<React.SetStateAction<SelectOptions>>;
}

export const Table: React.FC<TableProps> = ({
  data,
  columns,
  numberofTeams,
  teamname,
  BlurActive,
  tenantOption,
  workshopOptions,
  selectOptions,
  handleResetAll,
  isFetching,
  handleGameChange,
  getReset,
  getAdminLeaderBoard,
  isLoading,
  isSecondaryLoading,
}) => {
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [loading, setloading] = useState(false);
  const [teamFilter, setTeamFilter] = useState<string>("");
  const [period1Filter, setPeriod1Filter] = useState<string>("");
  const showToast = useCommonToast();
  let { timeData, authData, game3data, PermissionsList } = useGameStore();
  const location = useLocation();
  const isPlayable = location.pathname.includes("play");

  const filteredData = useMemo(() => {
    return data.filter(
      (row) =>
        (teamFilter === "" || row.team_name === teamFilter) &&
        (period1Filter === "" || row.PricePeriod1 === period1Filter)
    );
  }, [data, teamFilter, period1Filter]);

  const table = useReactTable({
    data: filteredData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  // const uniqueTeamNames = useMemo(() =>
  //   Array.from(new Set(data.map(row => row.team_name))).sort(),
  //   [data]
  // );

  // const uniquePeriod1Prices = useMemo(() =>
  //   Array.from(new Set(data.map(row => row.PricePeriod1))).sort((a, b) => Number(a) - Number(b)),
  //   [data]
  // );

  const maxTotalRevenue = useMemo(() => {
    const NotNullTeams = filteredData
      .map((row) => (row.total !== null ? parseFloat(row.total) || null : null))
      .filter((total) => total !== null);
    return NotNullTeams.length > 1 ? Math.max(...NotNullTeams) : 0;
  }, [filteredData]);

  const navigate = useNavigate();
  const goToGame3 = () => {
    isPlayable ? navigate("/play/game3") : navigate("/game3");
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedRows(filteredData.map((row) => row.team_id));
    } else {
      setSelectedRows([]);
    }
  };

  const handleSelectRow = (teamId: number) => {
    setSelectedRows((prev) =>
      prev.includes(teamId)
        ? prev.filter((id) => id !== teamId)
        : [...prev, teamId]
    );
  };

  const handleSendSelectedData = async () => {
    try {
      setloading(true);
      let response;
      if (PermissionsList && PermissionsList.name === "Admin") {
        response = await axiosClient.post("/workshop/reset/users/", {
          team_ids: selectedRows,
        });
        showToast("Success", response.data, "success");
        getAdminLeaderBoard(selectOptions.tenant_id, selectOptions.workshop_id);
      }
      if (PermissionsList && PermissionsList.name === "Instructor") {
        getReset(selectedRows);
      }
    } catch (error: any) {
      showToast("Error", error.response?.data, "error");
    } finally {
      setloading(false);
    }
  };

  const handleInsights = async () => {
    navigate("/insights");
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "230px",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "30vh",
      overflowY: "auto",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? "#2647B0"
        : state.isSelected
        ? "#2647B080"
        : provided.backgroundColor,
      color: state.isFocused ? "white" : provided.color,
      "&:hover": {
        backgroundColor: "#2647B0",
        color: "white",
      },
    }),
  };

  return (
    <div className="... mx-auto -mt-10 flex justify-center px-10 py-5">
      <div className="table-container flex flex-col justify-center">
        <div className="flex flex-col justify-between">
          <div className="mb-4 flex w-full justify-end gap-2">
            {PermissionsList &&
              checkSubResourcePermission(
                PermissionsList,
                "leaderboard",
                "DELETE"
              ) &&
              checkSubResourcePermission(PermissionsList, "client", "LIST") &&
              !isPlayable && (
                <>
                  <Select
                    classNamePrefix="select"
                    isClearable={true}
                    isSearchable={true}
                    options={tenantOption}
                    value={selectOptions.tenant_id || null}
                    placeholder="Select Client"
                    styles={customStyles}
                    onChange={(selectedOption) =>
                      handleGameChange(selectedOption, "tenant_id")
                    }
                  />
                  <Select
                    classNamePrefix="select"
                    isClearable={true}
                    isSearchable={true}
                    options={workshopOptions}
                    value={
                      selectOptions.tenant_id
                        ? selectOptions.workshop_id || null
                        : null
                    }
                    onChange={(selectedOption) =>
                      handleGameChange(selectedOption, "workshop_id")
                    }
                    placeholder="Select Workshop"
                    styles={customStyles}
                    isDisabled={!selectOptions.tenant_id || isFetching}
                    isLoading={isFetching}
                  />
                </>
              )}
            {/* <select
                value={teamFilter}
                onChange={(e) => setTeamFilter(e.target.value)}
                className="px-2 py-1 border rounded"
              >
                <option value="">All Teams</option>
                {uniqueTeamNames.map((team) => (
                  <option key={team} value={team}>{team}</option>
                ))}
              </select>
              <select
                value={period1Filter}
                onChange={(e) => setPeriod1Filter(e.target.value)}
                className="px-2 py-1 border rounded"
              >
                <option value="">All Period 1 Prices</option>
                {uniquePeriod1Prices.map(price => (
                  <option key={price} value={price}>${price}</option>
                ))}
              </select> */}
          </div>

          <div
            className={`flex flex-row ${
              selectedRows.length > 0 ? "justify-between" : "justify-between"
            } mb-2 w-full gap-2`}
          >
            <div className="flex gap-2">
              {selectedRows.length > 0 && (
                <Button
                  bgGradient="linear(to-b, #5C86DC,#2647B0)"
                  onClick={handleSendSelectedData}
                  width={{ base: "100%", md: "auto" }}
                  textColor="white"
                  fontWeight={700}
                  fontFamily="Poppins"
                  px={4}
                  py={2}
                  borderRadius="8px"
                  _hover={{}}
                  isLoading={loading}
                >
                  Reset
                </Button>
              )}
              {data &&
                data.length > 0 &&
                PermissionsList &&
                checkSubResourcePermission(
                  PermissionsList,
                  "leaderboard",
                  "DELETE"
                ) && (
                  <Button
                    bgGradient="linear(to-b, #5C86DC,#2647B0)"
                    onClick={handleInsights}
                    width={{ base: "100%", md: "auto" }}
                    textColor="white"
                    fontWeight={700}
                    fontFamily="Poppins"
                    px={4}
                    py={2}
                    borderRadius="8px"
                    _hover={{}}
                  >
                    Insights
                  </Button>
                )}
            </div>
            {game3data &&
              game3data?.time !== timeData[timeData?.length - 1].ui_time && (
                <Button
                  bgGradient="linear(to-b, #5C86DC,#2647B0)"
                  onClick={goToGame3}
                  width={{ base: "100%", md: "auto" }}
                  textColor="white"
                  fontWeight={700}
                  fontFamily="Poppins"
                  px={4}
                  py={2}
                  borderRadius="8px"
                  _hover={{}}
                >
                  Continue
                </Button>
              )}
          </div>

          <table className="border-slate-400 w-full border-separate border-spacing-2 text-center">
            <thead className="text-16 border-b font-poppins uppercase text-white">
              <tr>
                {PermissionsList &&
                  ["Admin", "Instructor"].includes(PermissionsList.name) &&
                  data.length > 0 && (
                    <th>
                      <Checkbox
                        onChange={handleSelectAll}
                        isChecked={selectedRows.length === filteredData.length}
                        size="lg"
                        iconColor="black"
                        sx={{
                          "span.chakra-checkbox__control": {
                            background: "transparent",
                            borderColor: "black",
                            _hover: {
                              background: "transparent",
                              borderColor: "black",
                            },
                          },
                        }}
                      />
                    </th>
                  )}
                {table.getHeaderGroups().map((headerGroup) =>
                  headerGroup.headers.map((header) => (
                    <th
                      id={header.id}
                      key={header.id}
                      className={`${
                        header.id.includes("actions")
                          ? "bg-transparent"
                          : "bg-zinc-900"
                      } font-400 px-4 py-1 text-xl`}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))
                )}
              </tr>
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row, index) => (
                <tr
                  key={row.id}
                  id={row.original.team_name.replace("team", "").trim()}
                  // className={
                  //   index % 2 === 0
                  //     ? "bg-zinc-500 h-[7vh] text-[#FAC55F]"
                  //     : "bg-zinc-200 h-[7vh] text-[#FCF66B]"
                  // }
                >
                  {PermissionsList &&
                    ["Admin", "Instructor"].includes(PermissionsList.name) && (
                      <td>
                        <Checkbox
                          isChecked={selectedRows.includes(
                            row.original.team_id
                          )}
                          onChange={() => handleSelectRow(row.original.team_id)}
                          size="lg"
                          iconColor="black"
                          sx={{
                            "span.chakra-checkbox__control": {
                              background: "transparent",
                              borderColor: "black",
                              _hover: {
                                background: "transparent",
                                borderColor: "black",
                              },
                            },
                          }}
                        />
                      </td>
                    )}
                  {row.getVisibleCells().map((cell, cellIndex) => (
                    <td
                      key={cell.id}
                      id={cell.id}
                      className={`text-yellowwt text-left ${
                        cell.column.columnDef.header
                          .toString()
                          .includes("Insights")
                          ? "w-[40%] text-sm"
                          : "text-xl"
                      } px-3 py-1 text-center font-poppins capitalize                         
                      ${
                        cell.id.includes("actions")
                          ? "bg-transparent"
                          : index % 2 === 0
                          ? "bg-zinc-500 px-3 py-1 text-[#FAC55F]"
                          : "bg-zinc-200 px-3 py-1 text-[#FCF66B]"
                      }
                      `}
                    >
                      {cell.column.id === "insights" && isSecondaryLoading ? (
                        <Skeleton>
                          <div className="h-full w-full">contents wrapped</div>
                        </Skeleton>
                      ) : (
                        <div className="flex flex-col">
                          {cell.column.id === "team_name" && (
                            <div className="flex items-center justify-between text-left">
                              <div className="ml-2">
                                {`${index + 1} | ${row.original.team_name}`}
                              </div>
                              {parseFloat(row.original.total) ===
                                maxTotalRevenue &&
                                numberofTeams > 1 &&
                                row.original.PricePeriod1 && (
                                  <FaTrophy className="h-7 w-7 text-lime-400" />
                                )}
                              {teamname ? (
                                (row.original.team_name.toLowerCase() ===
                                  teamname.toLowerCase() &&
                                  (parseFloat(row.original.total) !==
                                    maxTotalRevenue ||
                                    maxTotalRevenue === 0) && (
                                    <FaRedhat className="ml-2 h-7 w-7 text-navy-600" />
                                  )) ||
                                (row.original.team_name.toLowerCase() ===
                                  teamname.toLowerCase() &&
                                  index === 0 &&
                                  !row.original.PricePeriod1 && (
                                    <FaRedhat className="ml-2 h-7 w-7 text-navy-600" />
                                  ))
                              ) : (
                                <div />
                              )}
                            </div>
                          )}

                          {cell.column.id !== "team_name" && (
                            <div className="item-center flex justify-center text-left">
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </div>
                          )}

                          <div className="align-center flex items-center justify-center text-center">
                            {cell.column.id === "RevenuePeriod1" && (
                              <div className="align-center flex items-center justify-between sm:w-11/12 md:w-11/12 lg:w-11/12 xl:w-10/12 2xl:w-9/12">
                                <div
                                  className={`price-hexagon price-color h-[6%] w-10 ${
                                    cellIndex === 0
                                      ? "text-left"
                                      : "text-center"
                                  }`}
                                >
                                  <div className="font-small mr-2 text-xs text-white">
                                    {row.original.PricePeriod1
                                      ? `$${row.original.PricePeriod1}`
                                      : null}
                                  </div>
                                </div>
                                <div
                                  className={`inventory inventory-color h-[6%] w-10 text-center`}
                                >
                                  <div className="font-small text-xs  text-white">
                                    {row.original.inventory_Period1
                                      ? row.original.inventory_Period1
                                      : null}
                                  </div>
                                </div>
                              </div>
                            )}
                            {cell.column.id === "RevenuePeriod2" && (
                              <div className="align-center flex items-center justify-between sm:w-11/12 md:w-11/12 lg:w-11/12 xl:w-10/12 2xl:w-9/12">
                                <div className="price-hexagon price-color h-[6%] w-10 text-center">
                                  <div className="font-small mr-2 text-xs text-white">
                                    {row.original.PricePeriod2
                                      ? `$${row.original.PricePeriod2}`
                                      : null}
                                  </div>
                                </div>
                                <div
                                  className={`inventory inventory-color h-[6%] w-10 text-center`}
                                >
                                  <div className="font-small text-xs  text-white">
                                    {row.original.inventory_Period2
                                      ? row.original.inventory_Period2
                                      : null}
                                  </div>
                                </div>
                              </div>
                            )}
                            {cell.column.id === "RevenuePeriod3" && (
                              <div className="align-center flex items-center justify-between sm:w-11/12 md:w-11/12 lg:w-11/12 xl:w-10/12 2xl:w-9/12">
                                <div className="price-hexagon price-color h-[6%] w-10 text-center">
                                  <div className="font-small mr-2 text-xs text-white">
                                    {row.original.PricePeriod3
                                      ? `$${row.original.PricePeriod3}`
                                      : null}
                                  </div>
                                </div>
                                <div
                                  className={`inventory inventory-color h-[6%] w-10 text-center`}
                                >
                                  <div className="font-small text-xs  text-white">
                                    {row.original.inventory_Period3
                                      ? row.original.inventory_Period3
                                      : null}
                                  </div>
                                </div>
                              </div>
                            )}
                            {cell.column.id === "total" && (
                              <div
                                className={`inventory total-inventory-color h-[6%] w-10 text-center`}
                              >
                                <div className="font-small text-xs  text-white">
                                  {parseInt(row.original.inventory_balance) >= 0
                                    ? row.original.inventory_balance
                                    : null}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {BlurActive && (
        <div className="fixed inset-0 z-50 flex w-[100%] items-center justify-center overflow-y-auto overflow-x-hidden bg-[#3C1957] bg-opacity-50 backdrop-blur-[2px]"></div>
      )}
    </div>
  );
};
